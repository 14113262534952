import { msalInstance } from '../auth';
import { addDays, formatISO } from 'date-fns';
import { HostType, getLocalStorage, getDefaultHost } from '../reducers';

const HOST = {
    prod: 'https://backend.cognicount.fi',
    test: 'https://staging.backend.cognicount.fi',
    local_prod: 'http://localhost:8080',
    local_test: 'http://localhost:8080'
};
const AUDIENCE = {
    prod: 'https://backend.cognicount.fi',
    test: 'https://staging.backend.cognicount.fi',
    local_prod: 'https://backend.cognicount.fi',
    local_test: 'https://staging.backend.cognicount.fi'
};

export const getHost = (): string => {
    let host: HostType = getLocalStorage('setting', 'host', getDefaultHost());
    return HOST[host];
};

const getAudience = (): string => {
    const host: HostType = getLocalStorage('setting', 'host', getDefaultHost());
    return AUDIENCE[host];
};

export const loginRequest = {
    scopes: [`${getAudience()}/.default`]
};

export const getUser = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0)
        throw Error('Not authenticated');

    return await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount || accounts[0]
    });
};

export const isAuthenticated = () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    return activeAccount || accounts.length;
};

export const getToken = async () => {
    const response = await getUser();
    return response.accessToken;
};

export const dateRange = (startDate: Date | string, endDate: Date | string): string[] => {
    let dates: string[] = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    start.setHours(0, 0, 0);
    for (let date = start; date <= end; date = addDays(date, 1)){
        dates.push(formatISO(date, { representation: 'date' }));
    }
    return dates;
};

export const prevDates = (dateCount: number): string[] => {
    const endDate = new Date();
    const startDate = addDays(endDate, -dateCount);
    return dateRange(startDate, endDate);
};