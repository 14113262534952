import React, { useEffect, useMemo, useRef } from "react";
import _ from 'lodash';
import { Paper, Grid, Typography, Skeleton, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { StateRequestItem, StateRoot } from "../reducers";
import { filterLoad } from "../actions/data";
import { dateRange } from "../tools";
import { AppDataGrid, AppDataGridCols, DelayedSpinner, FilterBox } from "../components";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { language } from '../Theme';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Requests = ({ filterLoad, loading, requests, company, filter }: ConnectorProps) => {
    const loadRef = useRef<string>('');
    const theme = useTheme();

    const dates = useMemo(() => {
        return dateRange(filter.startDate, filter.endDate);
    }, [filter.startDate, filter.endDate]);

    useEffect(() => {
        const loadKey = Math.random().toString();
        loadRef.current = loadKey;
        filterLoad({
            requests: {
                include: {
                    date: dates
                }
            }
        }, loadKey);
    }, [filterLoad, dates]);

    const filtered = _.filter(requests, row => dates.includes(row.date));

    const getTs = (row: StateRequestItem) => (row.timestamp_updated || row.timestamp_added);
    const latestData = _.map(_.orderBy(filtered, getTs, 'desc'),
        row => ({
            id: row.id,
            timestamp: getTs(row),
            invoiceId: row.invoiceId,
            company: row.company && row.company in company ? company[row.company].name : row.company,
            status: row.status,
            elapsed: row.time_elapsed
        })
    );

    const latestFields: AppDataGridCols = useMemo(() => [
        {
            headerName: 'Updated',
            field: 'timestamp',
            minWidth: 160,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'string')
                    return new Date(params.value).toLocaleString(language);
                return 'N/A';
            }
        },
        {
            headerName: 'InvoiceId',
            field: 'invoiceId',
            minWidth: 90,
            flex: 1
        },
        {
            headerName: 'Company',
            field: 'company',
            minWidth: 110,
            flex: 1
        },
        {
            headerName: 'Status',
            field: 'status',
            minWidth: 80,
            flex: 1
        },
        {
            headerName: 'Elapsed',
            field: 'elapsed',
            minWidth: 80,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return `${params.value.toFixed(2)} s`;
                return '';
            },
            internal: true
        }
    ], []);

    const summaryData = _.orderBy(_.map(_.groupBy(filtered, 'date'), (group, date) => {
            return {
                id: date,
                date: Number(new Date(date)),
                rowCount: group.length
            }
        }), 'date', 'desc');

    const dateFormatter = (date: string | number) => new Date(date).toLocaleDateString(language);
    
    /*const summaryFields: AppDataGridCols = useMemo(() => [
        {
            headerName: 'Date',
            field: 'date',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'string')
                    return dateFormatter(params.value);
                return 'N/A';
            }
        },
        {
            headerName: 'Count',
            field: 'rowCount',
            flex: 1
        }
    ], []);*/

    const tooltipStyle = {
        backgroundColor: theme.palette.background.default,
        borderRadius: '3px'
    };

    const isLoading = loading[loadRef.current];
    const isFirstLoad = Boolean(isLoading && !Object.keys(requests).length);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
                <FilterBox>
                    <DelayedSpinner loading={Boolean(isLoading && !isFirstLoad)} />
                </FilterBox>
            </Grid>
            {/*
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem', minHeight: '8.5rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Summary</Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                        <Typography>Processed today</Typography>
                    </Box>
                </Paper>
            </Grid>
            */}
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{p: '1rem'}}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Latest</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='25rem' variant='rectangular' />
                        ) : (
                            <AppDataGrid rows={latestData} columns={latestFields} sx={{height: '25rem' }}/>
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Requests</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='25rem' variant='rectangular' />
                        ) : (
                            <ResponsiveContainer width='100%' height='30%' minHeight='25rem'>
                                <BarChart data={summaryData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="date" tickFormatter={dateFormatter}
                                        type='number' domain={['dataMin', 'dataMax']} />
                                    <YAxis />
                                    <Tooltip labelFormatter={dateFormatter}
                                        contentStyle={tooltipStyle} />
                                    <Legend />
                                    <Bar dataKey="rowCount" fill={theme.palette.primary.main} name="Count" />
                                </BarChart>
                            </ResponsiveContainer>
                        )
                    }
                </Paper>
            </Grid>
            {/*
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{p: '1rem'}}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Requests</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='30rem' variant='rectangular' />
                        ) : (
                            <AppDataGrid rows={summaryData} columns={summaryFields} />
                        )
                    }
                </Paper>
            </Grid>
            */}
        </Grid>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        loading: state.loading,
        requests: state.requests,
        company: state.company,
        filter: state.filter
    };
};

const connector = connect(
    mapStateToProps,
    { filterLoad }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Requests);