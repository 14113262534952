import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import WebsocketProvider from './socket';
import { MsalProvider } from "@azure/msal-react";
import ThemeProvider from './Theme';
import { msalInstance } from './auth';

// Initialize tracking
ReactGA.initialize('G-P5LRF614EN');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <WebsocketProvider>
                    <ThemeProvider>
                        <App />
                    </ThemeProvider>
                </WebsocketProvider>
            </Provider>
        </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
