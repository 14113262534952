import { DATA, STORE_RESET } from '../actions/actionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { LoadingAction, UpdateAction, FilterAction, SettingAction } from '../actions';
import { addDays, formatISO } from 'date-fns';

export const getDefaultHost = (): HostType => {
    const prod_origin = 'https://dashboard.cognicount.fi';
    const local_origin = 'http://localhost:3000';
    const origin = window.location.origin;
    if (origin === prod_origin)
        return 'prod';
    if (origin === local_origin)
        return 'local_prod';
    return 'test';
};

const storageKey = (item: string, key: string) => {
    return `${item}.${key}`;
};

const setLocalStorage = (item: string, key: string, value: any) => {
    if (localStorage)
        localStorage.setItem(storageKey(item, key), value);
};

export const getLocalStorage = (item: string, key: string, defaultValue: any = undefined) => {
    if (localStorage){
        const val = localStorage.getItem(storageKey(item, key));
        if (val !== null)
            return val;
    }
    return defaultValue;
};

export interface StateLoading {
    [key: string]: boolean
}

export interface StateFilter {
    startDate: string,
    endDate: string
}

export type HostType = 'local_test' | 'local_prod' | 'test' | 'prod';
export interface StateSetting {
    colorMode: 'light' | 'system' | 'dark'
    userMode: 'true' | 'false'
    demoMode: 'true' | 'false'
    host: HostType
}

export interface StateUser {
    id: string
    name: string
    upn: string
    tenantId: string
    internal: Boolean
    clientId: '*' | string[]
    email?: string | null
}

export enum RowStatus {
    Pending = 'pending',
    Completed = 'completed',
    Errored = 'errored'
}
interface StateBaseItem {
    id: string
    clientId: string
    status: RowStatus
    date: string
    timestamp_added: string
    errors?: string[] | string
    company?: string
    _ts: number
    _lsn: number
}

export interface ResultItem {
    value: string
    confidence?: number
}
export interface StateRequestItem extends StateBaseItem {
    invoiceId: string
    results?: {[label: string]: ResultItem}[]
    rowCount?: number
    timestamp_updated?: string
    time_elapsed?: number
    accountingId?: string
    error_code?: string
}

export interface ReconciledItem {
    match: number
    value?: string
    prediction?: string
    confidence?: number
}
export interface StateAccountingItem extends StateBaseItem {
    invoiceId?: string
    results?: object[]
    rowCount?: number
    invoiceStatus?: string
    invoiceHandling?: string
    requestId?: string
    requestDate?: string
    reconciled?: {[label: string]: ReconciledItem}[]
    matchCount?: { [label: string]: number }
    requestRowCount?: number
    supplier?: string
    payment?: number
    invoiced?: object
}

export interface StateCompanyItem {
    company: string
    name: string
    clientId: string
    externalId?: string
    _ts: number
    _lsn: number
}

export interface StateRequests {
    [id: string]: StateRequestItem
}

export interface StateAccounting {
    [id: string]: StateAccountingItem
}

export interface StateCompany {
    [id: string]: StateCompanyItem
}

export interface StateBaseRoot {
    requests: StateRequests
    accounting: StateAccounting
    company: StateCompany
}

export interface StateDataRoot extends StateBaseRoot {
    user: StateUser | null
}

export interface StateRoot extends StateDataRoot {
    filter: StateFilter
    setting: StateSetting
    loading: StateLoading
}

const initialState: StateRoot = {
    loading: {},
    filter: {
        startDate: formatISO(addDays(new Date(), - 14), { representation: 'date' }),
        endDate: formatISO(new Date(), { representation: 'date' })
    },
    setting: {
        colorMode: getLocalStorage('setting', 'colorMode', 'system'),
        userMode: getLocalStorage('setting', 'userMode', 'false'),
        demoMode: getLocalStorage('setting', 'demoMode', 'false'),
        host: getLocalStorage('setting', 'host', getDefaultHost())
    },
    user: null,
    requests: {},
    accounting: {},
    company: {}
};

type BaseRootItem = StateAccounting | StateRequests | StateCompany;
const conditionalUpdate = (stateItem: BaseRootItem, data: BaseRootItem): void => {
    for (const [id, item] of Object.entries(data)){
        if (!(id in stateItem) || item._lsn > stateItem[id]._lsn){
            stateItem[id] = item;
        }
    }
}

export default createReducer(initialState, builder => {
    builder
        .addCase(DATA.UPDATE, (state: StateRoot, action: UpdateAction) => {
            if (action.key)
                state.loading[action.key] = false;
            if (action.payload.user !== undefined){
                state.user = action.payload.user;
            }
            if (action.payload.requests !== undefined){
                if (Object.keys(state.requests).length){
                    conditionalUpdate(state.requests, action.payload.requests);
                } else {
                    state.requests = action.payload.requests;
                }
            }
            if (action.payload.accounting !== undefined){
                if (Object.keys(state.accounting).length){
                    conditionalUpdate(state.accounting, action.payload.accounting);
                } else {
                    state.accounting = action.payload.accounting;
                }
            }
            if (action.payload.company !== undefined){
                if (Object.keys(state.company).length){
                    conditionalUpdate(state.company, action.payload.company);
                } else {
                    state.company = action.payload.company;
                }
            }
        })
        .addCase(DATA.LOADING, (state: StateRoot, action: LoadingAction) => {
            state.loading[action.key] = true;
        })
        .addCase(DATA.FILTER, (state: StateRoot, action: FilterAction) => {
            state.filter = {
                ...state.filter,
                ...action.payload
            }
        })
        .addCase(DATA.SETTING, (state: StateRoot, action: SettingAction) => {
            state.setting = {
                ...state.setting,
                ...action.payload
            };
            for (const [key, val] of Object.entries(action.payload))
                setLocalStorage('setting', key, val);
        })
        .addCase(STORE_RESET, (state: StateRoot, action: Action) => {
            return initialState;
        });
});