import React, { useEffect, useMemo, useRef } from 'react';
import ReactGA from 'react-ga4';
import Container from '@mui/material/Container';
import { useMsal } from "@azure/msal-react";
import MenuBar from './containers/MenuBar';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { loginRequest } from './tools';
import { Route, Routes } from 'react-router-dom';
import routes from './routes';
import _ from 'lodash';
import BottomNavi from './containers/BottomNavi';
import { StateRoot } from './reducers';
import { connect, ConnectedProps } from 'react-redux';
import { filterLoad, loadUser } from "./actions/data";
import UnauthorizedPage from './containers/UnauthorizedPage';


const App = ({ loadUser, filterLoad, loading, user, userMode }: ConnectorProps) => {
    useEffect(() => {
        if (user)
            ReactGA.set({
                userId: user.id,
                tenantId: user.tenantId,
                internal: user.internal
            });
    }, [user]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const account = activeAccount ? activeAccount.homeAccountId : null;
    const loadRef = useRef<string | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    
    const filteredRoutes = useMemo(() => {
        return _.pickBy(routes, route => {
            if (!user && route.protected)
                return false;
            if (!user || ((!user.internal || userMode) && route.internal))
                return false;
            return true;
        });
    }, [user, userMode]);
    const hasMenuRoutes = Boolean(_.filter(filteredRoutes, route => Boolean(route.menu)).length);

    useEffect(() => {
        if (!account)
            return;
        const loadKey = Math.random().toString();
        loadRef.current = loadKey;
        loadUser(loadKey);
        filterLoad({
            company: {
                include: {}
            }
        });
    }, [loadUser, filterLoad, account]);

    const content = (() => {
        if (user) {
            return (
                <Routes>
                    {
                        _.map(filteredRoutes, route => (
                            <Route key={route.key} path={route.path} element={route.content}/>
                        ))
                    }
                </Routes>
            );
        } else if (account && loadRef.current && !loading[loadRef.current]){
            return <UnauthorizedPage />;
        } else {
            if (inProgress === 'none' && !account)
                instance.loginRedirect({ ...loginRequest, prompt: 'select_account' });
            return (
                <Box>
                    Signing in <CircularProgress style={{position: 'relative', top: '0.9rem', left: '1rem'}} />
                </Box>
            );
        }
    })();

    return (
        <>
            <MenuBar routes={filteredRoutes} />
            <Container maxWidth="xl" sx={{pb: isMobile && hasMenuRoutes ? '4.5rem' : '1rem', marginTop: '1rem'}}>
                {
                    content
                }
            </Container>
            {
                hasMenuRoutes ? (
                    <BottomNavi routes={filteredRoutes} />
                ) : null
            }
        </>
    );
}

const mapStateToProps = (state: StateRoot) => {
    return {
        loading: state.loading,
        user: state.user,
        userMode: state.setting.userMode === 'true'
    };
};

const connector = connect(
    mapStateToProps,
    { loadUser, filterLoad }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(App);