import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';

const UnauthorizedPage = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const unauthorizedMessage = activeAccount
        ? `User ${activeAccount.username} does not have access on tenant ${activeAccount.tenantId}`
        : 'Please contact your system administrator';

    return (
        <Paper elevation={2} sx={{p: '1rem'}}>
            <Typography variant="h5" sx={{ mb: '1rem' }}>Unauthorized</Typography>
            <Typography>
                {unauthorizedMessage}
            </Typography>
        </Paper>
    );
};

export default UnauthorizedPage;