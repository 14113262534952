import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteMap } from "../routes";

interface BottomNaviProps {
    routes: RouteMap
};

const BottomNavi = ({ routes }: BottomNaviProps ) => {
    const location = useLocation();
    const navigate = useNavigate();

    const navEvent = (event: React.SyntheticEvent, newValue: string) => {
        navigate(newValue);
    }

    const filtered = _.filter(routes, route => Boolean(route.menu?.bottomNavi));

    if (!filtered.length)
        return null;

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,
                     display: { sm: 'none', md: 'none', lg: 'none', xl: 'none' } }} elevation={4}>
            <BottomNavigation showLabels value={location.pathname} onChange={navEvent} sx={{background: 'none'}}>
                {
                    _.map(filtered, route => (
                        <BottomNavigationAction key={route.key} label={route.menu?.label} value={route.menu?.to}
                            icon={route.menu?.icon && <route.menu.icon />} />
                    ))
                }
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavi;