import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getHost, getToken } from './tools';
import { connect, ConnectedProps } from 'react-redux';
import { updateData } from './actions/data';
import { useMsal } from '@azure/msal-react';
import { StateRoot } from './reducers';

const WebsocketContext = createContext<null | WebSocket>(null);

export { WebsocketContext };

let reconnectTimer: null | NodeJS.Timeout = null;

interface ContextProps extends ConnectorProps {
    children: React.ReactNode
}
const Context = ({ children, updateData, demoMode }: ContextProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const account = activeAccount ? activeAccount.homeAccountId : null;
    const [socketNum, setSocket] = useState(1);

    const socket = useMemo(() => {
        if (!account || !socketNum)
            return null;
        const host = getHost().replace('http', 'ws');
        return new WebSocket(`${host}/data/ws`);
    }, [account, socketNum]);

    useEffect(() => {
        if (!socket)
            return;
        console.log('Attaching listeners to socket');

        socket.onopen = async () => {
            if (reconnectTimer)
                clearInterval(reconnectTimer);
            console.log('Socket connection opened, authenticating');
            const token = await getToken();
            if (token && socket)
                socket.send(JSON.stringify({
                    event: 'auth',
                    payload: {
                        token: token,
                        demo: demoMode || undefined
                    }
                }));
        };

        socket.onmessage = (ev) => {
            const message = JSON.parse(ev.data);
            if (message.event === 'ping'){
                if (socket)
                    socket.send(JSON.stringify({
                        event: 'pong'
                    }));
            } else if (message.event === 'data'){
                updateData(message.payload);
            }
        };

        socket.onclose = () => {
            reconnectTimer = setTimeout(() => {
                console.log('Websocket connection lost, reconnecting');
                setSocket(Math.random());
            }, 5000);
        };

        socket.onerror = () => {
            if (socket)
                socket.close();
        };
    }, [updateData, setSocket, socket, demoMode]);

    return (
        <WebsocketContext.Provider value={socket}>
            {children}
        </WebsocketContext.Provider>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        demoMode: state.setting.demoMode === 'true'
    };
};

const connector = connect(
    mapStateToProps,
    { updateData }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Context);