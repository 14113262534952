import { AuthenticationResult, Configuration,  PublicClientApplication } from "@azure/msal-browser";

const clientId = '773d7dee-72ed-4dd8-9c78-decfc94c8107';
//const tenantId = '8d398c92-6ee3-46c4-bc7a-912b1500204f';
const tenantId = 'common';

const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}/`,
        redirectUri: `${window.location.origin}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/logout`
    }
};
export const msalInstance = new PublicClientApplication(msalConfig);

const handleResponse = (response: AuthenticationResult | null ) => {
    if (response !== null) {
        msalInstance.setActiveAccount(response.account);
    }
};

msalInstance.handleRedirectPromise().then(handleResponse);