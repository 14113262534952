import { FilterAction, FilterPayload, LoadingAction, UpdateAction, UpdatePayload } from './index';
import { SettingAction, SettingPayload } from './index';
import { Action } from 'redux';
import { DATA, STORE_RESET } from "./actionTypes";
import { getHost, getToken } from '../tools';
import axios from 'axios';
import { StateBaseRoot, StateRoot } from '../reducers';
import { AppDispatch } from '../store';

export const updateData = (data: UpdatePayload, key: string | undefined = undefined): UpdateAction => ({
    type: DATA.UPDATE,
    payload: data,
    key: key
});

export const setLoading = (key: string): LoadingAction => ({
    type: DATA.LOADING,
    key: key
});

export const setFilter = (payload: FilterPayload): FilterAction => ({
    type: DATA.FILTER,
    payload: payload
});

export const setSetting = (payload: SettingPayload): SettingAction => ({
    type: DATA.SETTING,
    payload: payload
});

export const resetStore = (): Action => ({
    type: STORE_RESET
});

type IncludeValue = number | string | boolean
interface ItemInclude {
    [key: string]: IncludeValue | IncludeValue[];
}

interface ItemQuery {
    include: ItemInclude | ItemInclude[]
    exclude?: string[]
    fields?: string[]
}

type FetchQuery = {
    [Property in keyof StateBaseRoot]?: ItemQuery
}

const updateExcludeFilter = (state: StateRoot, filter: FetchQuery) => {
    (Object.keys(filter) as Array<keyof FetchQuery>).forEach(key => {
        const item = filter[key];
        if (item)
            item.exclude = Object.values(state[key]).map(val => val._lsn);
    });
};

const updateFilter = (state: StateRoot, filter: FetchQuery) => {
    updateExcludeFilter(state, filter);
    return filter;
};

export const loadUser = (key: string = Math.random().toString()) => {
    return (dispatch: AppDispatch) => {
        dispatch(setLoading(key));
        return getToken().then(token => axios({
            method: 'GET',
            url: `${getHost()}/data/user`,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).then(data => {
            dispatch(updateData(data.data, key));
            return true;
        }).catch(err => {
            dispatch(updateData({ user: null }, key));
            return false;
        });
    };
};

export const filterLoad = (filter: FetchQuery, key: string = Math.random().toString()) => {
    return (dispatch: AppDispatch, getState: () => StateRoot) => {
        dispatch(setLoading(key));
        const state = getState();
        return getToken().then(token => axios({
            method: 'POST',
            url: `${getHost()}/data/fetch`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: updateFilter(state, filter),
            params: {
                demo: state.setting.demoMode === 'true' || undefined
            }
        })).then(data => {
            dispatch(updateData(data.data, key));
            return true;
        }).catch(err => {
            console.error(err);
            dispatch(updateData({}, key));
            return false;
        });
    };
};

