import React, { useMemo } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import fiLocale from 'date-fns/locale/fi';
import enLocale from 'date-fns/locale/en-US';
import { connect, ConnectedProps } from 'react-redux';
import { StateRoot } from './reducers';

interface LocaleMap {
    [language: string]: typeof fiLocale
}
const localeMap: LocaleMap = {
    'fi-FI': fiLocale,
    'en-US': enLocale
};

interface MaskMap {
    [language: string]: string
}
const maskMap: MaskMap = {
    'fi-FI': '__.__.____',
    'en-US': '__/__/____'
};

export const language = navigator.language;
export const locale = language in localeMap ? localeMap[language] : enLocale;
export const mask = language in maskMap ? maskMap[language] : maskMap['en-US'];

interface ExtendedWindow extends Window {
    theme: Theme
};
declare let window: ExtendedWindow;

interface ProviderProps extends ConnectorProps {
    children: React.ReactNode
}
const Provider = ({ children, colorMode }: ProviderProps) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(() => {
        const mode = (colorMode === 'dark' || (colorMode === 'system' && prefersDarkMode))
            ? 'dark' : 'light';
        return createTheme({
            palette: {
                mode: mode,
                ...(mode === 'light' ? {
                    primary: {
                        light: '#42629a',
                        main: '#05386b',
                        dark: '#001340',
                        contrastText: '#fff',
                    },
                    secondary: {
                        light: '#c0ffe0',
                        main: '#8de2ae',
                        dark: '#5cb07f',
                        contrastText: '#000',
                    }
                } : {
                    // Lets not overwrite dark mode yet
                })
            },
        })
    }, [prefersDarkMode, colorMode]);
    window.theme = theme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                { children }
            </LocalizationProvider>
        </ThemeProvider>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        colorMode: state.setting.colorMode
    };
};

const connector = connect(
    mapStateToProps,
    { }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Provider);