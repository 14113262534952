import React from "react";
import { DataGrid, DataGridProps, GridColDef } from "@mui/x-data-grid";
import { StateRoot } from "../reducers";
import { connect, ConnectedProps } from "react-redux";

export interface AppDataGridColDef extends GridColDef {
    internal?: boolean
}

export type AppDataGridCols = AppDataGridColDef[];

export interface AppDataGridProps extends DataGridProps, ConnectorProps {
    columns: AppDataGridColDef[]
}

const AppDataGrid = ({ user, userMode, columns, rows, sx }: AppDataGridProps) => {
    const filteredColumns = columns.filter(col => {
        if (col.internal){
            return user && user.internal && !userMode;
        }
        return true;
    });
    return <DataGrid rows={rows} columns={filteredColumns}
        density='compact' sx={{height: '30rem', border: 'none', ...sx}} />
};

const mapStateToProps = (state: StateRoot) => {
    return {
        user: state.user,
        userMode: state.setting.userMode === 'true'
    };
};

const connector = connect(
    mapStateToProps,
    {}
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(AppDataGrid);