import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import Requests from './containers/Requests';
import Results from './containers/Results';
import Invoicing from './containers/Invoicing';
import Analytics from './containers/Analytics';

import BoltIcon from '@mui/icons-material/Bolt';
import TableViewIcon from '@mui/icons-material/TableView';
import EuroIcon from '@mui/icons-material/Euro';
import AnalyticsIcon from '@mui/icons-material/Analytics';

export interface MenuRoute {
    label: string
    to: string
    bottomNavi?: boolean
    icon?: typeof BoltIcon
}

export interface PageRoute {
    key: string
    path: string
    content: ReactNode
    title?: string
    menu?: MenuRoute
    internal?: boolean
    protected?: boolean
}

export interface RouteMap {
    [key: string]: PageRoute
}

const pageRoutes: RouteMap = {
    'main': {
        key: 'main',
        path: '/',
        content: <Navigate to='requests' />,
        protected: true
    },
    'callback': {
        key: 'callback',
        path: '/callback',
        content: <Navigate to='/requests' />,
        protected: true
    },
    'requests': {
        key: 'requests',
        title: 'Requests',
        path: '/requests/*',
        content: <Requests />,
        menu: {
            label: 'Requests',
            to: '/requests',
            icon: BoltIcon,
            bottomNavi: true
        },
        protected: true
    },
    'results': {
        key: 'results',
        title: 'Results',
        path: '/results/*',
        content: <Results />,
        menu: {
            label: 'Results',
            to: '/results',
            icon: TableViewIcon,
            bottomNavi: true
        },
        protected: true
    },
    'invoicing': {
        key: 'invoicing',
        title: 'Invoicing',
        path: '/invoicing/*',
        content: <Invoicing />,
        menu: {
            label: 'Invoicing',
            to: '/invoicing',
            icon: EuroIcon,
            bottomNavi: true
        },
        internal: true,
        protected: true
    },
    'analytics': {
        key: 'analytics',
        title: 'Analytics',
        path: '/analytics/*',
        content: <Analytics />,
        menu: {
            label: 'Analytics',
            to: '/analytics',
            icon: AnalyticsIcon,
            bottomNavi: true
        },
        internal: true,
        protected: true
    },
    'default': {
        key: 'default',
        path: '/*',
        content: 'Unknown page'
    }
};

export default pageRoutes;