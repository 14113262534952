import React from "react";
import { Paper, Grid, Typography, Link } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { StateRoot } from "../reducers";
import { filterLoad } from "../actions/data";

const Analytics = ({ filterLoad }: ConnectorProps) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{p: '1rem'}}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Analytics</Typography>
                    <Link href='https://analytics.google.com/analytics'
                        target='_blank' rel='noopener'>Go to Google Analytics</Link>
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
    };
};

const connector = connect(
    mapStateToProps,
    { filterLoad }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Analytics);