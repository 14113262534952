import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Button, Container, Divider, ListItemIcon }  from '@mui/material';
import { IconButton, List, ListItem } from '@mui/material';
import { ListItemText, SwipeableDrawer, ToggleButton }  from '@mui/material';
import { ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { Toolbar, Tooltip, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { RouteMap, PageRoute } from '../routes';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../tools';
import { connect, ConnectedProps } from 'react-redux';
import { StateRoot } from '../reducers';
import { setSetting } from "../actions/data";

const title = 'Cognicount';

interface AppBarProps extends ConnectorProps {
    routes: RouteMap
};

const ResponsiveAppBar = ({ user, routes, setting, setSetting }: AppBarProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const account = activeAccount ? activeAccount.homeAccountId : null;
    const [showSettingsDrawer, setSettingsDrawer] = useState(false);
    const [showMenuDrawer, setMenuDrawer] = useState(false);

    const theme = useTheme();
    const menuDrawerEnabled = useMediaQuery(theme.breakpoints.only('sm'));
    useEffect(() => {
        if (!menuDrawerEnabled)
            setMenuDrawer(false);
    }, [menuDrawerEnabled])

    const menuRoutes = useMemo(() => {
        return _.filter(routes, route => Boolean(route.menu));
    }, [routes]);
    const hasMenuRoutes = Boolean(menuRoutes.length);

    const navigate = useNavigate();

    const navigateTo = (route: PageRoute) => {
        setMenuDrawer(false);
        if (route.menu) {
            navigate(route.menu.to);
        }
    };

    const loginAnother = () => {
        instance.loginRedirect({ ...loginRequest, prompt: 'select_account' });
    };

    const logout = () => {
        instance.logoutRedirect();
    };

    const userMenuItems = [
        {
            label: 'Change user',
            action: loginAnother,
            icon: AccountCircle
        },
        {
            label: 'Logout',
            action: logout,
            icon: LogoutIcon
        }
    ];

    const userMenu = account && (
        <Box sx={{ mt: '1rem' }}>
            <Divider />
            <List>
                {
                    _.map(userMenuItems, item => (
                        <ListItem key={item.label} button onClick={item.action}>
                            <ListItemIcon>
                                {
                                    <item.icon />
                                }
                            </ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    );

    const settingsMenu = (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings menu">
                <IconButton onClick={() => setSettingsDrawer(true)} sx={{ p: 0 }} color="inherit">
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <SwipeableDrawer anchor='right' open={showSettingsDrawer}
                onClose={() => setSettingsDrawer(false)}
                onOpen={() => setSettingsDrawer(true)}>
                <Box role='presentation' sx={{ width: '18rem' }}>
                    <Typography variant='h5' sx={{ml: '1rem', mt: '0.5rem', mb: '0.5rem'}}>Settings</Typography>
                    <Divider />
                    <ToggleButtonGroup color='primary' value={setting.colorMode} size='small'
                        exclusive sx={{ml: '0.6rem', mt: '1rem'}}
                        onChange={(e, val) => setSetting({ colorMode: val })}>
                        <ToggleButton value='light'><LightModeIcon sx={{mr: '0.5rem'}} /> Light</ToggleButton>
                        <ToggleButton value='system'><SettingsBrightnessIcon sx={{mr: '0.5rem'}} /> System</ToggleButton>
                        <ToggleButton value='dark'><DarkModeIcon sx={{mr: '0.5rem'}} /> Dark</ToggleButton>
                    </ToggleButtonGroup>
                    {
                        user && user.internal ? (
                            <>
                                <ToggleButtonGroup color='primary' value={setting.userMode} size='small'
                                    exclusive sx={{ml: '0.6rem', mt: '1rem'}}
                                    onChange={(e, val) => setSetting({ userMode: val })}>
                                    <ToggleButton value='false'><AdminPanelSettingsIcon sx={{mr: '0.5rem'}} /> Admin</ToggleButton>
                                    <ToggleButton value='true'><PersonIcon sx={{mr: '0.5rem'}} /> UserMode</ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup color='primary' value={setting.demoMode} size='small'
                                    exclusive sx={{ml: '0.6rem', mt: '1rem'}}
                                    onChange={(e, val) => setSetting({ demoMode: val })}>
                                    <ToggleButton value='false'><CheckIcon sx={{mr: '0.5rem'}} /> Live</ToggleButton>
                                    <ToggleButton value='true'><InsightsIcon sx={{mr: '0.5rem'}} /> Demomode</ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup color='primary' value={setting.host} size='small'
                                    exclusive sx={{ml: '0.6rem', mt: '1rem'}}
                                    onChange={(e, val) => setSetting({ host: val })}>
                                    <ToggleButton value='prod'>Prod</ToggleButton>
                                    <ToggleButton value='test'>Test</ToggleButton>
                                    <ToggleButton value='local_prod'>Loc-Prod</ToggleButton>
                                    <ToggleButton value='local_test'>Loc-Test</ToggleButton>
                                </ToggleButtonGroup>
                            </>
                        ) : null
                    }
                    {userMenu}
                </Box>
            </SwipeableDrawer>
        </Box>
    );

    const drawerMenu = hasMenuRoutes ? (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'none' } }}>
            <IconButton size="large"
                aria-label="open menu"
                aria-controls="menu-appbar" aria-haspopup="true"
                onClick={() => setMenuDrawer(true)} color="inherit">
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer anchor='left' open={showMenuDrawer}
                onClose={() => setMenuDrawer(false)}
                onOpen={() => setMenuDrawer(true)}>
                <Box role='presentation' sx={{ width: '18rem' }}>
                    <List>
                        {
                            _.map(menuRoutes, route => (
                                <ListItem key={route.key} button onClick={() => navigateTo(route)}>
                                    <ListItemIcon>
                                        {
                                            route.menu && route.menu.icon ? <route.menu.icon /> : null
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={route.menu?.label} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
            </SwipeableDrawer>
        </Box>
    ) : null;

    const topMenu = (
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
                _.map(menuRoutes, route => (
                    <Button key={route.key} onClick={() => navigateTo(route)} sx={{ my: 2, color: 'white', display: 'block' }}>
                        {route.menu?.label}
                    </Button>
                ))
            }
        </Box>
    );

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                        <img src='/logo192_white.png' alt='' style={{ height: '2rem' }} />
                    </Box>
                    {drawerMenu}
                    <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
                        <img src='/logo192_white.png' alt='' style={{ height: '2rem', marginRight: '0.5rem' }} /> {title}
                    </Typography>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
                        {title}
                    </Typography>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'none' } }}>
                        <img src='/logo192_white.png' alt='' style={{ height: '2rem', marginRight: '0.5rem' }} />{title}
                    </Typography>
                    {topMenu}
                    {settingsMenu}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        setting: state.setting,
        user: state.user
    };
};

const connector = connect(
    mapStateToProps,
    { setSetting }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(ResponsiveAppBar);