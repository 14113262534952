import React from 'react';
import { DatePicker } from '@mui/lab';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { StateRoot } from '../reducers';
import { connect, ConnectedProps } from 'react-redux';
import { setFilter } from "../actions/data";
import { formatISO } from 'date-fns';

interface FilterBoxProps extends ConnectorProps {
    children: React.ReactNode
}
const FilterBox = ({ children, startDate, endDate, setFilter }: FilterBoxProps) => {
    const setDate = (key: string, val: Date | null) => {
        if (val) setFilter({ [key]: formatISO(val, { representation: 'date' }) });
    };
    return (
        <Paper elevation={2} sx={{ p: '1rem' }}>
            <Typography variant="h5" sx={{ mb: '1rem' }}>Filter</Typography>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                <DatePicker label="Start date" value={new Date(startDate)} disableMaskedInput={true}
                    onChange={(val) => setDate('startDate', val)}
                    renderInput={(params) => <TextField {...params} sx={{width: '10rem'}} />} />
                <DatePicker label="End date" value={new Date(endDate)} disableMaskedInput={true}
                    onChange={(val) => setDate('endDate', val)}
                    renderInput={(params) => <TextField {...params} sx={{width: '10rem'}} />} />
                {children}
            </Box>
        </Paper>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        ...state.filter
    };
};

const connector = connect(
    mapStateToProps,
    { setFilter }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(FilterBox);
