import React from "react";
import { CircularProgress, Fade } from "@mui/material";

const DelayedSpinner = ({ loading }: { loading: boolean }) => {
    return (
        <Fade in={loading}
            style={{ transitionDelay: loading ? '800ms' : '0ms' }} unmountOnExit>
            <CircularProgress sx={{ position: 'relative', top: '0.3rem' }} />
        </Fade>
    );
};

export default DelayedSpinner;