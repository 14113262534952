import React, { useEffect, useMemo, useRef } from "react";
import _ from 'lodash';
import { Box, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { StateRoot } from "../reducers";
import { filterLoad } from "../actions/data";
import { dateRange } from "../tools";
import { AppDataGrid, AppDataGridCols, DelayedSpinner, FilterBox } from "../components";
import { language } from '../Theme';
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Invoicing = ({ filterLoad, loading, accounting, filter }: ConnectorProps) => {
    const loadRef = useRef<string>('');
    const theme = useTheme();

    const dates = useMemo(() => {
        return dateRange(filter.startDate, filter.endDate);
    }, [filter.startDate, filter.endDate]);

    useEffect(() => {
        const loadKey = Math.random().toString();
        loadRef.current = loadKey;
        filterLoad({
            accounting: {
                include: {
                    date: dates
                }
            }
        }, loadKey);
    }, [filterLoad, dates]);

    const data = _.orderBy(_.map(_.groupBy(_.filter(
        accounting, row => dates.includes(row.date)), 'date'), (group, date) => {
            return {
                id: date,
                date: Number(new Date(date)),
                outstanding_amount: _.sumBy(_.filter(group, row => !Boolean(row.invoiced)), 'payment'),
                invoiced_amount: _.sumBy(_.filter(group, row => _.get(row, 'invoiced.status') === 'completed'), 'payment'),
                row_count: _.sumBy(_.filter(group, row => typeof row.payment == 'number'), 'rowCount')
            };
        }), 'date', 'desc');

    const dateFormatter = (date: string | number) => new Date(date).toLocaleDateString(language);
    const fields: AppDataGridCols = useMemo(() => [
        {
            headerName: 'Date',
            field: 'date',
            flex: 1,
            minWidth: 90,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return dateFormatter(params.value);
                return 'N/A';
            }
        },
        {
            headerName: 'Outstanding',
            field: 'outstanding_amount',
            flex: 1,
            minWidth: 110,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return `${params.value.toFixed(2)} €`;
                return 'N/A';
            }
        },
        {
            headerName: 'Invoiced',
            field: 'invoiced_amount',
            flex: 1,
            minWidth: 110,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return `${params.value.toFixed(2)} €`;
                return 'N/A';
            }
        },
        {
            headerName: 'RowCount',
            field: 'row_count',
            flex: 1,
            minWidth: 100
        }
    ], []);

    const ccyFormatter = Intl.NumberFormat(language, {
        style: 'currency',
        currency: 'EUR'
    });
    const paymentFormatter = (val: number) => `${(ccyFormatter.format(val))}`;
    const tooltipStyle = {
        backgroundColor: theme.palette.background.default,
        borderRadius: '3px'
    };

    const isLoading = loading[loadRef.current];
    const isFirstLoad = Boolean(isLoading && !Object.keys(accounting).length);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <FilterBox>
                    <DelayedSpinner loading={Boolean(isLoading && !isFirstLoad)} />
                </FilterBox>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem', minHeight: '8.5rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Summary</Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                        <Typography>Invoiced { paymentFormatter(_.sumBy(data, 'invoiced_amount')) }</Typography>
                        <Typography>Outstanding { paymentFormatter(_.sumBy(data, 'outstanding_amount')) }</Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Payment by date</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='25rem' variant='rectangular' />
                        ) : (
                            <ResponsiveContainer width='100%' height='30%' minHeight='25rem'>
                                <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="date" tickFormatter={dateFormatter}
                                        type='number' domain={['dataMin', 'dataMax']} />
                                    <YAxis tickFormatter={paymentFormatter} />
                                    <Tooltip formatter={paymentFormatter} labelFormatter={dateFormatter}
                                        contentStyle={tooltipStyle} />
                                    <Legend />
                                    <Bar dataKey="invoiced_amount" fill={theme.palette.primary.light} name="Invoiced" stackId='payment' />
                                    <Bar dataKey="outstanding_amount" fill={theme.palette.primary.main} name="Outstanding" stackId='payment' />
                                </BarChart>
                            </ResponsiveContainer>
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Invoicing</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='25rem' variant='rectangular' />
                        ) : (
                            <AppDataGrid rows={data} columns={fields} sx={{height: '25rem'}} />
                        )
                    }
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        loading: state.loading,
        accounting: state.accounting,
        filter: state.filter
    };
};

const connector = connect(
    mapStateToProps,
    { filterLoad }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Invoicing);
