import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import { Paper, FormControl, InputLabel, MenuItem, Select, Typography, Grid, Autocomplete, TextField, useTheme, Skeleton } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { StateAccountingItem, StateRoot } from "../reducers";
import { filterLoad } from "../actions/data";
import { dateRange } from "../tools";
import { Legend, XAxis, YAxis, Tooltip, BarChart, Bar, ResponsiveContainer, LineChart, Line } from "recharts";
import { AppDataGrid, AppDataGridCols, DelayedSpinner, FilterBox } from "../components";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { language } from "../Theme";
import { differenceInDays } from "date-fns";

interface ByField {
    match: number
    count: number
}
interface ByDate {
    match: number
    count: number
    processTime: number
    rowCount: number
}
interface ByEntity {
    match: number
    count: number
    processTime: number
    rowCount: number
}
interface RawData {
    byField: { [field: string]: ByField }
    byDate: { [field: string]: ByDate }
    byCompany: { [field: string]: ByEntity }
    bySupplier: { [field: string]: ByEntity }
};

interface SelectedEntity {
    id: string
    label: string
}
const Results = ({ filterLoad, accounting, company, loading, filter, user, userMode }: ConnectorProps) => {
    const [dateType, setDateType] = useState<string>('date');
    const useAccDate = dateType === 'date';
    const [invoiceHandling, setInvoiceHandling] = useState<string>('all');
    const [multiRow, setMultiRow] = useState<string>('all');
    const [selectedCompany, setCompany] = useState<SelectedEntity | null>(null);
    const [selectedSupplier, setSupplier] = useState<SelectedEntity | null>(null);
    const loadRef = useRef<string>('');
    const theme = useTheme();

    const initialSupplier: { [key: string]: 1 } = {};
    const suppliers = Object.keys(Object.values(accounting).reduce((res, val) => {
        if (val.supplier && !(val.supplier in res))
            res[val.supplier] = 1;
        return res;
    }, initialSupplier)).sort();

    const dates = useMemo(() => {
        return dateRange(filter.startDate, filter.endDate);
    }, [filter.startDate, filter.endDate]);
    useEffect(() => {
        if (dates.length) {
            const loadKey = Math.random().toString();
            loadRef.current = loadKey;
            filterLoad({
                accounting: {
                    include: {
                        [dateType]: dates
                    }
                }
            }, loadKey);
        }
    }, [filterLoad, dates, dateType]);

    const rawDataBegin: RawData = {
        byField: {},
        byDate: {},
        byCompany: {},
        bySupplier: {}
    };

    const getProcessTime = (val: StateAccountingItem) => {
        if (!val.requestDate)
            return 1;
        const date = new Date(val.date);
        const reqDate = new Date(val.requestDate);
        return differenceInDays(date, reqDate);
    };
    const rawData: RawData = _.reduce(accounting, (res, val) => {
        const date = useAccDate ? val.date : val.requestDate;
        if (!date || !dates.includes(date))
            return res;
        if (val.requestRowCount && val.matchCount) {
            if ((multiRow === 'multi' && val.requestRowCount === 1) ||
                (multiRow === 'single' && val.requestRowCount > 1))
                return res;
            if (invoiceHandling !== 'all' && val.invoiceHandling !== invoiceHandling)
                return res;
            if (selectedCompany != null && val.company !== selectedCompany.id)
                return res;
            if (selectedSupplier != null && val.supplier !== selectedSupplier.id)
                return res;
            const process_time = getProcessTime(val);
            if (!(date in res.byDate)){
                res.byDate[date] = {
                    match: 0,
                    count: 0,
                    processTime: 0,
                    rowCount: 0
                }
            }
            res.byDate[date].processTime += process_time;
            res.byDate[date].rowCount += 1;
            if (val.company && !(val.company in res.byCompany)){
                res.byCompany[val.company] = {
                    match: 0,
                    count: 0,
                    processTime: 0,
                    rowCount: 0
                };
            }
            if (val.company){
                res.byCompany[val.company].processTime += process_time;
                res.byCompany[val.company].rowCount += 1;
            }
            if (val.supplier && !(val.supplier in res.bySupplier)){
                res.bySupplier[val.supplier] = {
                    match: 0,
                    count: 0,
                    processTime: 0,
                    rowCount: 0
                };
            }
            if (val.supplier){
                res.bySupplier[val.supplier].processTime += process_time;
                res.bySupplier[val.supplier].rowCount += 1;
            }
            for (const key in val.matchCount) {
                if (!(key in res.byField))
                    res.byField[key] = {
                        match: 0,
                        count: 0
                    }
                res.byField[key].match += val.matchCount[key];
                res.byField[key].count += val.requestRowCount;
                if (key !== 'all') {
                    res.byDate[date].match += val.matchCount[key];
                    res.byDate[date].count += val.requestRowCount;
                    if (val.company){
                        res.byCompany[val.company].match += val.matchCount[key];
                        res.byCompany[val.company].count += val.requestRowCount;
                    }
                    if (val.supplier){
                        res.bySupplier[val.supplier].match += val.matchCount[key];
                        res.bySupplier[val.supplier].count += val.requestRowCount;
                    }
                }
            }
        }
        return res;
    }, rawDataBegin);

    const byField = _.map(rawData.byField, (val, field) => ({
        field: field,
        accuracy: val.match / val.count
    }));
    const byDate = _.orderBy(_.map(rawData.byDate, (val, date) => ({
        date: Number(new Date(date)),
        accuracy: val.match / val.count,
        process_time: val.processTime / val.rowCount,
        accuracy_rolling_avg: 0,
        process_time_rolling_avg: 0
    })), 'date', 'asc');
    const daySum = 7;
    for (let i = 0; i < byDate.length; i++) {
        const start = _.max([0, i - 7]);
        byDate[i].accuracy_rolling_avg = _.meanBy(byDate.slice(start, i + 1), 'accuracy');
        byDate[i].process_time_rolling_avg = _.meanBy(byDate.slice(start, i + 1), 'process_time');
    }
    const byCompany = _.orderBy(_.map(rawData.byCompany, (val, companyKey) => ({
        id: companyKey,
        company: companyKey,
        name: companyKey in company ? company[companyKey].name : company,
        accuracy: val.match / val.count,
        row_count: val.rowCount,
        process_time: val.processTime / val.rowCount
    })), 'row_count', 'desc');
    const bySupplier = _.orderBy(_.map(rawData.bySupplier, (val, supplier) => ({
        id: supplier,
        supplier: supplier,
        name: supplier,
        accuracy: val.match / val.count,
        row_count: val.rowCount,
        process_time: val.processTime / val.rowCount
    })), 'row_count', 'desc');

    const byEntityFields: AppDataGridCols = useMemo(() => [
        {
            headerName: 'Name',
            field: 'name',
            minWidth: 160,
            flex: 1
        },
        {
            headerName: 'Accuracy',
            field: 'accuracy',
            minWidth: 90,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return accuracyFormatter(params.value);
                return 'N/A';
            }
        },
        {
            headerName: 'Count',
            field: 'row_count',
            minWidth: 100,
            flex: 1
        },
        {
            headerName: 'Avg process time',
            field: 'process_time',
            minWidth: 100,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (typeof params.value === 'number')
                    return processTimeFormatter(params.value);
                return 'N/A';
            }
        }
    ], []);

    const accuracyFormatter = (acc: number) => `${(acc * 100).toFixed(1)}%`;
    const processTimeFormatter = (acc: number) => `${(acc).toFixed(1)} days`;
    const dateFormatter = (date: string | number) => new Date(date).toLocaleDateString(language);
    const tooltipStyle = {
        backgroundColor: theme.palette.background.default,
        borderRadius: '3px'
    };

    const isLoading = loading[loadRef.current];
    const isFirstLoad = Boolean(isLoading && !Object.keys(accounting).length);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FilterBox>
                    <FormControl>
                        <InputLabel id='results-select-dateType-label'>Date type</InputLabel>
                        <Select labelId='results-select-dateType-label' value={dateType} sx={{ minWidth: '5rem' }}
                            label="Date type" onChange={(e) => setDateType(e.target.value)}>
                            <MenuItem value='date'>Accounting</MenuItem>
                            <MenuItem value='requestDate'>Request</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        user && user.internal && !userMode ? (
                            <>
                                <FormControl>
                                    <InputLabel id='results-select-invoiceHandling-label'>Handling</InputLabel>
                                    <Select labelId='results-select-invoiceHandling-label' value={invoiceHandling} sx={{ minWidth: '5rem' }}
                                        label="InvoiceHandling" onChange={(e) => setInvoiceHandling(e.target.value)}>
                                        <MenuItem value='AI'>AI</MenuItem>
                                        <MenuItem value='RPA'>RPA</MenuItem>
                                        <MenuItem value='all'>Both</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id='results-select-multirow-label'>Row type</InputLabel>
                                    <Select labelId='results-select-multirow-label' value={multiRow}
                                        label="RowCount" onChange={(e) => setMultiRow(e.target.value)}>
                                        <MenuItem value='single'>Single row</MenuItem>
                                        <MenuItem value='multi'>Multi row</MenuItem>
                                        <MenuItem value='all'>Both</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        ) : null
                    }
                    <FormControl>
                        <Autocomplete disablePortal value={selectedCompany} onChange={(e, val) => setCompany(val)}
                            options={_.map(company, (val, key) => ({ id: key, label: val.name}))}
                            sx={{ minWidth: '12rem' }} renderInput={(params) => <TextField {...params} label='Company' />} />
                    </FormControl>
                    <FormControl>
                        <Autocomplete disablePortal value={selectedSupplier} onChange={(e, val) => setSupplier(val)}
                            options={suppliers.map(val => ({ id: val, label: val}))}
                            sx={{ minWidth: '12rem' }} renderInput={(params) => <TextField {...params} label='Supplier' />} />
                    </FormControl>
                    <DelayedSpinner loading={Boolean(isLoading && !isFirstLoad)} />
                </FilterBox>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Accuracy by field</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='20rem' variant='rectangular' />
                        ) : (
                            <ResponsiveContainer width='100%' height='30%' minHeight='20rem'>
                                <BarChart data={byField} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="field" />
                                    <YAxis domain={[0, 1]} tickFormatter={accuracyFormatter} />
                                    <Tooltip formatter={accuracyFormatter} contentStyle={tooltipStyle} />
                                    <Legend />
                                    <Bar dataKey="accuracy" fill={theme.palette.primary.main} name='Accuracy' />
                                </BarChart>
                            </ResponsiveContainer>
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Accuracy by date</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='20rem' variant='rectangular' />
                        ) : (
                            <ResponsiveContainer width='100%' height='30%' minHeight='20rem'>
                                <LineChart data={byDate} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="date" tickFormatter={dateFormatter}
                                        type='number' domain={['dataMin', 'dataMax']} />
                                    <YAxis domain={['dataMin - 0.1', 1]} tickFormatter={accuracyFormatter} />
                                    <Tooltip formatter={accuracyFormatter} labelFormatter={dateFormatter}
                                        contentStyle={tooltipStyle} />
                                    <Legend />
                                    <Line dataKey="accuracy" stroke={theme.palette.primary.main} name="Accuracy" dot={false} />
                                    {
                                        dates.length >= 30 && <Line dataKey="accuracy_rolling_avg" stroke={theme.palette.primary.light}
                                            name={`${daySum} day rolling average`} strokeDasharray="6 3"
                                            dot={false} legendType='plainline' />
                                    }
                                </LineChart>
                            </ResponsiveContainer>
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Accuracy by company</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='20rem' variant='rectangular' />
                        ) : (
                            <AppDataGrid rows={byCompany} columns={byEntityFields} />
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={2} sx={{ p: '1rem' }}>
                    <Typography variant="h5" sx={{ mb: '1rem' }}>Accuracy by supplier</Typography>
                    {
                        isFirstLoad ? (
                            <Skeleton width='100%' height='20rem' variant='rectangular' />
                        ) : (
                            <AppDataGrid rows={bySupplier} columns={byEntityFields} />
                        )
                    }
                </Paper>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: StateRoot) => {
    return {
        accounting: state.accounting,
        company: state.company,
        loading: state.loading,
        filter: state.filter,
        user: state.user,
        userMode: state.setting.userMode === 'true'
    };
};

const connector = connect(
    mapStateToProps,
    { filterLoad }
);
type ConnectorProps = ConnectedProps<typeof connector>;

export default connector(Results);
